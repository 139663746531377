'use client';

import { useMemo, useEffect, useReducer, useCallback } from 'react';
import { signIn, signOut } from '@thehyoosik/nexus-api-connector-js/authentication';
import {
  Accommodation,
  AccommodationStatusType,
} from '@thehyoosik/nexus-api-connector-js/dist/domain/accommodation';
import {
  MemberDetail,
  fetchCurrentMember,
  fetchAccommodationListOfMember,
} from '@thehyoosik/nexus-api-connector-js/member';

import { AuthContext } from './auth-context';
import { ActionMapType, AuthStateType } from '../../types';
import { setLocal, isValidToken, getRefreshToken } from './utils';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    member: MemberDetail | null;
    accommodationList: Accommodation[] | null;
  };
  [Types.LOGIN]: {
    member: MemberDetail | null;
    accommodationList: Accommodation[] | null;
    accessToken: string;
  };
  [Types.REGISTER]: {
    member: MemberDetail | null;
    accessToken: string;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  member: null,
  accommodationList: [],
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      member: action.payload.member,
      accommodationList: action.payload.accommodationList,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      member: action.payload.member,
      accommodationList: action.payload.accommodationList,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      member: action.payload.member,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      member: null,
      accommodationList: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const STORAGE_KEY = 'accessToken';
export const STORAGE_ACCOMMODATION_ID = 'accommodationId';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setLocal(accessToken);
        await getRefreshToken();

        const member = await fetchCurrentMember({ accessToken });

        const { accommodationList } = await fetchAccommodationListOfMember({
          accessToken,
          memberId: member.currentMember.id,
          status: [AccommodationStatusType.ON_SALE],
        });

        dispatch({
          type: Types.INITIAL,
          payload: {
            member: {
              ...member.currentMember,
            },
            accommodationList: {
              ...accommodationList,
            },
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            member: null,
            accommodationList: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          member: null,
          accommodationList: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      email,
      password,
    };

    const { accessToken } = await signIn({
      username: data.email,
      password: data.password,
    });

    setLocal(accessToken);

    const { currentMember } = await fetchCurrentMember({ accessToken });

    const { accommodationList } = await fetchAccommodationListOfMember({
      accessToken,
      memberId: currentMember.id,
      status: [AccommodationStatusType.ON_SALE],
    });

    dispatch({
      type: Types.LOGIN,
      payload: {
        member: {
          ...currentMember,
        },
        accommodationList: {
          ...accommodationList,
        },
        accessToken,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      // const data = {
      //   email,
      //   password,
      //   firstName,
      //   lastName,
      // };
      // const res = await axios.post(endpoints.auth.register, data);
      // const { accessToken, member } = res.data;
      // localStorage.setItem(STORAGE_KEY, accessToken);
      // dispatch({
      //   type: Types.REGISTER,
      //   payload: {
      //     member: {
      //       ...member,
      //       accessToken,
      //     },
      //   },
      // });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    const accessToken = localStorage.getItem(STORAGE_KEY);

    if (accessToken) {
      await signOut({ accessToken });
      localStorage.removeItem(STORAGE_KEY);
    }

    setLocal(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.member ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      member: state.member,
      accommodationList: state.accommodationList ? state.accommodationList : null,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.member, state.accommodationList, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
