import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

interface LoadingScreenProps extends BoxProps {
  type?: 'linear' | 'circular';
}

export default function LoadingScreen({ type = 'linear', sx }: LoadingScreenProps) {
  return type === 'linear' ? (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
}
